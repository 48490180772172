
.board .task {
    margin: 5px;
    border-radius: 5px;
    /*border: 1px solid gray;*/
    padding: 10px;
    background-color: white;
    box-shadow: 2px 2px #ededed;
}

.card-body p {
    margin-left: 22px;
    margin-bottom: 10px;
}

.card-assigned{
    width: 100%;
    padding-top: 10px;
}

    .card-assigned p {
        margin: 1px 0 0 30px;
    }

.card-title {
    padding: 0 !important;
}

.card-check{
    margin: 2px;
}


.board-content .card {
    box-shadow: 0 0 8px gainsboro;
}

.swimlane {
    border-bottom: 1px solid lightgray;
    /*margin-bottom: 15px;*/
    display: flex;
    max-height: calc(100%);
    padding: 4px 0;
}

.swimlane-title {
    padding: 7px;
    width: 100%;
}

    .swimlane-title p {
        margin: 0;
        font-size: 13px;
        color: gray;
    }

    .swimlane-title h6 {
        font-size: 0.9em;
        margin-bottom: 0;
    }

.swimlane-title-content {
    cursor: pointer;
}

.swimlane-content {
    width: 100%;
    display: contents;
}

.swimlane-collapse {
    position: absolute;
    top: 0;
    right: 0;
    /*height: 100%;*/
    font-size: 21px;
    z-index: -1;
}

.column-header {
    padding: 5px 10px;
}

.column-title {
    margin-bottom: 0px;
    font-size: 0.9em;
}

.board-col-first {
    flex: 0 0 19%;
    max-width: 19%;
    display: flex;
    position:relative;
}

.board-col {
    /*flex: 0 0 27%;
    max-width: 27%;*/
    /*padding: 0 10px;*/
    /*max-height: 818px;*/        
}

    .board-col::-webkit-scrollbar {
        width: 7px;
    }

/*.board-frame::-webkit-scrollbar-track {
        background-color: darkgrey;
        border-radius: 50px;
    }*/

    .board-col::-webkit-scrollbar-thumb {
        background-color: gainsboro;
        border-radius: 50px;
    }

.board-col-content {
    overflow-y: auto;
    height: calc(100% - 30px);
    padding: 0 10px;
}

    .board-col-content::-webkit-scrollbar {
        width: 7px;
    }

    /*.board-frame::-webkit-scrollbar-track {
        background-color: darkgrey;
        border-radius: 50px;
    }*/

    .board-col-content::-webkit-scrollbar-thumb {
        background-color: gainsboro;
        border-radius: 50px;
    }

.board-col-first, .board-col {
    /*position: relative;
    display: table-cell;*/
}

.board-col-header {
    position: relative;
    padding: 5px 10px;
}

.board-col .btn {
}

.board-header {
    padding-right: 10px;
}

.board-header, .board-frame {
    /*border: 1px solid black;*/
    right: 1em;
    left: 1em;
    margin: 0 2em;
    position: absolute;
    min-width: 700px;
    display: flex;
}

.board-frame {
    height: calc(100% - 105px);
    overflow-y: scroll;
    margin-top: 1.8em;


    /*width: 100%;
    height: 100%;
    margin: 0;*/
    /*display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;
    height: calc(100% - 80px);
    width: calc(100% - 80px);
    position: absolute;*/
}

    
    .board-frame::-webkit-scrollbar {
        width: 10px;
    }

    /*.board-frame::-webkit-scrollbar-track {
        background-color: darkgrey;
        border-radius: 50px;
    }*/

    .board-frame::-webkit-scrollbar-thumb {
        background-color: lightsteelblue;
        border-radius: 50px;
    }


.board-content {

    width: 100%;
    /*padding-top: 3px;*/

    /* display: table;
    table-layout: fixed;
    display: table-row;
    overflow-y: auto;*/
    /* overflow-x: auto; */
    /*position: absolute;*/
    /* height: calc(100% - 100px);
    overflow: auto;*/

/*    overflow-y: scroll;
    min-height: 0;
    -webkit-box-flex: 1;
    flex: 1;*/
}

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}