
.login {
    /* padding: 7rem 0;*/
    /*    background-image: url('login-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;*/


    display: table;
    position: absolute;
    top: 47px;
    left: 0;
    height: calc(100% - 47px);    
}

.login-container {
    display: table-cell;
    vertical-align: middle;
}

.login .form-control {
    border: none;
    background: transparent;
    border-bottom: 1px solid #ccc;
    padding: 0;    
    border-radius: 0;
}

    .login p.mb-4 {
        color: #b3b3b3;
        font-weight: 300;
        margin-bottom: 30px!important;
    }

.login-bg {
    background-image: url('login-bg.png');
    background-size: contain;     
    background-repeat: no-repeat;
    background-position: right 15% center;
}

.login .contents {
    padding: 124px 0;
}

.login-form {
    max-width: 400px;
    min-width: 280px;
    background-color: rgba(255,255,255,0.8);
}

.login .form-control {
    margin-bottom: 15px;
}

.login .form-group input {
    height: 35px;
}

    .login .form-group input:focus {
        box-shadow: none;
        
    }



    .login label {

    
    font-size: 12px;
    
    margin-bottom: 0;
    color: #b3b3b3;

    /*position: absolute;
    top: 50%;*/
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    }

.login .btn-block {
    width: 100%;
}