/*body {
    margin-top: 20px;
    background: #fafafa
}
*/
.card {
    margin-bottom: 1.5rem;
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .025)
}

.card-border-primary {
    border-top: 4px solid #2979ff
}

.card-border-secondary {
    border-top: 4px solid #efefef
}

.card-border-success {
    border-top: 4px solid #00c853
}

.card-border-info {
    border-top: 4px solid #3d5afe
}

.card-border-warning {
    border-top: 4px solid #ff9100
}

.card-border-danger {
    border-top: 4px solid #ff1744
}

.card-border-light {
    border-top: 4px solid #f8f9fa
}

.card-border-dark {
    border-top: 4px solid #6c757d
}

.card-actions a {
    color: #495057;
    text-decoration: none
}

.card-actions svg {
    width: 16px;
    height: 16px
}

.card-actions .dropdown {
    line-height: 1.4
}

.card-title {
    font-weight: 500;
}

.card-subtitle {
    font-weight: 400
}

.card-table {
    margin-bottom: 0
}

    .card-table tr td:first-child,
    .card-table tr th:first-child {
        padding-left: 1.25rem
    }

    .card-table tr td:last-child,
    .card-table tr th:last-child {
        padding-right: 1.25rem
    }

.card-img-top {
    height: 100%
}

.card {
    margin-bottom: .7rem;
    margin-top: .7rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.025);
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e5e9f2;
    border-radius: .2rem;
}

.task-header {
    padding: 0rem 1.25rem;   
}

.card-body{
    padding:10px;
    font-size: 12px;
}

    .card-body p {
        padding: 2px;
    }




.parent {
    position: relative;
    display: table;
    padding: 10px;
    border: 1px solid red;
    width: 500px;
}

.child {
    position: relative;
    display: table-cell;
    padding: 10px;
    border: 1px solid green;
}

.rrow {
    border: 1px solid black;
    right: 1em;
    left: 1em;
    margin: 0 2em;
    position: absolute;
    min-width: 700px;
}

.row-content {
    height: calc(100% - 180px);
    overflow-y: scroll;   
    margin-top: 4.3em;
}